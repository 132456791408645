import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as dashActions from '../../../../actions';
import { ReduxState } from '../../../../../../types/redux_types';
import { Action, Note } from '../../../../../../types/global_reducer';

interface ActionsGroupProps {
  isDisabled: boolean;
  note: Note;
  actions: {
    editNote: (accountId: string | null | undefined, newNote: Note) => void;
    deleteNote: (accountId: string | null | undefined, timestamp: string, newNote: Note) => void;
  };
  accountId?: string | null;
  setEditMode: () => void;
  cancelEdit: () => void;
  title: string;
  body: string;
}

const ActionsGroup = ({
  isDisabled,
  note,
  actions,
  accountId,
  setEditMode,
  cancelEdit,
  title,
  body,
}: ActionsGroupProps): JSX.Element => {
  const [mode, setMode] = useState('default');

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMode('edit');
    setEditMode();
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setMode('delete');
    setEditMode();
  };

  const cancelEditClick = () => {
    setMode('default');
    cancelEdit();
    setEditMode();
  };

  const editNote = () => {
    setMode('default');
    // If the note didn't change the name but hits save, don't bother
    const newNote: Note = {
      title,
      body,
      timestamp: note.timestamp,
    };

    if (note.title !== title.trim() || note.body !== body.trim()) {
      actions.editNote(accountId, newNote);
    }
    setEditMode();
  };

  const deleteNote = () => {
    actions.deleteNote(accountId, note.timestamp, note);
  };

  switch (mode) {
    case 'edit': {
      return (
        <div className="note-actions edit" data-test="note-actions edit">
          <span className="edit-container">
            <h5>Save Note?</h5>
            <button type="button" data-test="save" onClick={editNote} disabled={isDisabled}>
              <i className="fas fa-check" />
            </button>
            <button
              type="button"
              className="cancel"
              data-test="cancel-save"
              onClick={cancelEditClick}
            >
              <i className="fas fa-ban" />
            </button>
          </span>
        </div>
      );
    }

    case 'delete': {
      return (
        <div className="note-actions delete" data-test="note-actions delete">
          <span className="delete-container">
            <h5>Delete Note?</h5>
            <button
              type="button"
              className="confirm-delete"
              data-test="confirm-delete"
              onClick={deleteNote}
              disabled={isDisabled}
            >
              <i className="fas fa-check" />
            </button>
            <button type="button" className="cancel" data-test="delete" onClick={cancelEditClick}>
              <i className="fas fa-ban" />
            </button>
          </span>
        </div>
      );
    }

    default: {
      return (
        <div className="note-actions" data-test="note-actions">
          <button type="button" data-test="delete-note" onClick={handleDeleteClick}>
            <i className="fas fa-trash" />
          </button>
          <button
            type="button"
            className="edit-note"
            data-test="edit-note"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt" />
          </button>
        </div>
      );
    }
  }
};

/* istanbul ignore next */
const mapStateToProps = (state: ReduxState) => {
  const results = {
    accountId: state.global.selectedAccountId,
  };
  return results;
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    deleteNote: (accountId: string | null | undefined, noteId: string, note: Note) =>
      dispatch(dashActions.deleteNote(accountId || '', noteId, note)),
    editNote: (accountId: string | null | undefined, newNote: Note) =>
      dispatch(dashActions.editNote(accountId || '', newNote)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionsGroup);
