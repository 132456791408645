import { ActionType, DeleteMessageAction, SetMessageAction } from '../types/global_reducer';

// ---------------- clear messages

export const clearMessage = (): DeleteMessageAction => ({ type: ActionType.DELETE_MESSAGE });

// ---------------- set messages

export const setMessage = (value: {
  type: string;
  message: string[];
  icon: string;
}): SetMessageAction => ({
  type: ActionType.SET_MESSAGE,
  payload: value,
});
