import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { History, Location } from 'history';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ResetForm from './components/ResetForm';
import { logout } from '../../actions';
import { setSearchFieldValue, clearSelectedAccount } from '../../actions/common';
import './index.css';
import { ReduxState } from '../../types/redux_types';
import { Action } from '../../types/global_reducer';
import { JSMap } from '../../types/javascript_object';

interface ResetPasswordProps {
  match: {
    params: { userID: string };
    isExact: boolean;
    path: string;
    url: string;
  };
  history: History;
  actions: {
    logout: () => void;
  };
  isAuthenticated: boolean;
  /* eslint-disable-next-line react/no-unused-prop-types */
  location: Location;
}

const ResetPassword = ({
  match: {
    params: { userID },
  },
  history,
  actions,
  isAuthenticated,
}: ResetPasswordProps) => {
  const { d20436UpdateSsoToUseUrlSearchParams: showNewRecordButton } = useFlags();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | undefined>();

  function getUrlVars() {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('ticket');
  }

  function previousGetUrlVars() {
    var vars: JSMap<string> = {};
    window.location.href.matchAll(/[?&]+([^=&]+)=([^&]*)/gi)?.forEach(match => {
      const key = match[1];
      const value = match[2];
      vars[key] = value;
    });
    return vars;
  }

  useEffect(() => {
    if (isAuthenticated) {
      actions.logout();
    }

    const ticket = showNewRecordButton ? getUrlVars() : previousGetUrlVars()?.ticket;

    if (!ticket) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      actions.logout();
    }
  }, [isAuthenticated, actions]);

  const handleSubmit = ({ password }: { password: string }) => {
    const ticket = showNewRecordButton ? getUrlVars() : previousGetUrlVars()?.ticket;

    fetch(`/api/v1/changePassword/${userID}?ticket=${ticket}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
      .then(async response => {
        if (response.status !== 200) {
          switch (response.status) {
            case 400:
            case 404:
              return setError('Invalid password reset request.');
            default:
              return setError('Unable to process your reset request.');
          }
        }
        return response;
      })
      .then(() => history.push('/login'))
      .catch(() => {
        return setError('Unable to process your reset request.');
      });
  };

  return (
    <section className="reset-password-container">
      <ResetForm formSubmit={handleSubmit} />
    </section>
  );
};

export default connect(
  (state: ReduxState) => ({
    isAuthenticated: state.global.isAuthenticated,
  }),
  (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
    actions: {
      logout: () => {
        localStorage.clear();
        sessionStorage.clear();

        dispatch(logout());

        dispatch(setSearchFieldValue());
        dispatch(clearSelectedAccount());
      },
    },
  })
)(withRouter<ResetPasswordProps, typeof ResetPassword>(ResetPassword));
