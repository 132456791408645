import {
  InternalUserAccountsShape,
  AccountDetailsShape,
  // eslint-disable-next-line import/no-unresolved
} from '@gogoair/utils/build/types/Globals/src/types';
import { getApiData, postPasswordResetApi } from '../lib/restUtils';

const apiUrl = '/api';
const API_VERSION = 'v1';

/* pull down all aircraft for this account */
// eslint-disable-next-line import/prefer-default-export
export const apiGetInternalAccounts = () => {
  // bend api: http://aircraft.dashdata.dev.gogoair.com/v1/accounts
  const apiURL = `${apiUrl}/${API_VERSION}/accounts`;
  return getApiData<(InternalUserAccountsShape | AccountDetailsShape)[]>(apiURL);
};

export const apiForgotPassword = (email: string) => {
  const apiURL = `${apiUrl}/${API_VERSION}/forgotPassword`;
  return postPasswordResetApi<void>(apiURL, { email });
};
