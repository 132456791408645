import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const ResetSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Must be at least 8 characters').required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Confirm password is required'),
});

interface ResetFormProps {
  formSubmit: (values: { password: string; confirmPassword: string }) => void;
}

const ResetForm = ({ formSubmit }: ResetFormProps): JSX.Element => (
  <div className="inner-reset-password">
    <h1>Reset Password</h1>
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={values => formSubmit(values)}
      validate={values => {
        const errors: { confirmPassword?: string } = {};

        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Passwords must match';
        }

        return errors;
      }}
      validationSchema={ResetSchema}
    >
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <div className="reset-password-inputs">
            <Field type="password" placeholder="Password" name="password" />
            <Field type="password" placeholder="Confirm password" name="confirmPassword" />
          </div>
          {!!touched.confirmPassword && !!errors.confirmPassword && (
            <p style={{ margin: '0.5rem 0 0', flex: '1' }}>{errors.confirmPassword}</p>
          )}
          <button type="submit">Reset Password</button>
        </form>
      )}
    </Formik>
  </div>
);

export default ResetForm;
