import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { loginAs } from '../../actions';
import View from './view';
import { ReduxState } from '../../types/redux_types';
import { JSMap } from '../../types/javascript_object';
import { Action } from '../../types/global_reducer';

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.global.isAuthenticated,
  apps: state.global.userDetails.apps,
  accountId: state.global.selectedAccountId,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    loginAs: (
      name: string,
      accountList: JSMap<{ accountId: string; accountName: string }[]>,
      token: string,
      email: string,
      cob_unverified: boolean
    ) => {
      dispatch(loginAs(name, accountList, token, email, cob_unverified));
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
