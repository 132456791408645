/* eslint-disable object-curly-newline */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';
import NavigationBar from '../../components/NavigationBar';
import { loginAs, logout } from '../../actions';
import { postApi, getUserData } from '../../lib/restUtils';
import logger from '../../logger';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import { setMessage } from '../../actions/messages';
import _variables from './_variables';

import './index.css';
import gogoLogo from '../../assets/GogoLogoSquareWhite.png';
import { ReduxState } from '../../types/redux_types';
import { Action } from '../../types/global_reducer';
import { JSAnyMap, JSMap } from '../../types/javascript_object';

const setupLocalStorage = (authResult: JSAnyMap) => {
  const expiresAt = new Date().getTime() + 1000 * authResult.expiresIn;
  const permissionedAccounts = JSON.stringify(authResult['https://dash.gogoair.com/applications']);
  const cobUnverified = authResult.cob_unverified === undefined ? false : authResult.cob_unverified;
  // add user info
  localStorage.setItem('expires_at', String(expiresAt));
  localStorage.setItem('email', authResult.email);
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('token', authResult.token);
  localStorage.setItem('name', authResult.name);
  localStorage.setItem('email', authResult.email);
  localStorage.setItem('cob_unverified', String(cobUnverified));
  localStorage.setItem('permissionedAccounts', permissionedAccounts);
};

interface LoginProps {
  match: { path: string };
  actions: {
    logout: () => void;
    loginAs: (
      name: string,
      accountList: JSMap<{ accountId: string; accountName: string }[]>,
      token: string,
      email: string,
      cob_unverified: boolean
    ) => void;
    setMessage: (message: { type: string; message: string[]; icon: string }) => void;
  };
}

const Login = ({ actions, match: { path } }: LoginProps) => {
  const [username, setUsername] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    actions.logout();
    // Remove everything except savedURL to ensure auth0 doesn't go pear-shaped.
    const savedURL = localStorage.getItem('savedURL');
    localStorage.clear();
    if (savedURL) {
      localStorage.setItem('savedURL', savedURL);
    }
    sessionStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authenticate = async ({
    username: uname,
    password: pass,
  }: {
    username: string;
    password: string;
  }) => {
    setFormSubmitting(true);

    const errorMessage = {
      type: 'alert',
      message: ['The wrong email and/or password was provided'],
      icon: 'fa-comment',
    };
    await postApi<{
      error: string;
      error_description: string;
      access_token: string;
      expires_in: number;
      id_token: string;
    }>('/api/v1/authenticate', {
      username: uname,
      password: pass,
    })
      .then(async resp => {
        if (resp.results) {
          if (resp.results.error) {
            actions.setMessage({
              type: 'alert',
              message: [resp.results.error_description],
              icon: 'fa-comment',
            });
          } else if (resp.results.access_token) {
            const expiresIn = resp.results.expires_in;
            const idToken = resp.results.id_token;

            await getUserData<JSAnyMap>(
              `https://${_variables.domain}/userinfo`,
              resp.results.access_token
            ).then(resp2 => {
              if (resp2) {
                const { results } = resp2;
                if (results) {
                  const accountList = results['https://dash.gogoair.com/applications'];
                  const token = results.sub;
                  if (
                    Object.keys(accountList).length ||
                    accountList['*'] ||
                    results.cob_unverified
                  ) {
                    const { name, email, cob_unverified } = results;
                    setupLocalStorage({ expiresIn, idToken, token, ...results });
                    actions.loginAs(name, accountList, token, email, cob_unverified);
                  } else {
                    actions.setMessage(errorMessage);
                  }
                } else {
                  actions.setMessage(errorMessage);
                }
              } else {
                logger.warn(resp2);
              }
            });
          }
        } else if (resp.status === 504) {
          actions.setMessage({
            type: 'alert',
            message: ['Login Gateway Timeout'],
            icon: 'fa-comment',
          });
        }
      })
      .catch((err: { error: string }) => {
        logger.warn(`Error: ${err.error}. Check the console for further details.`);
      })
      .finally(() => {
        if (!localStorage.getItem('name')) {
          setFormSubmitting(false);
        }
      });
  };

  const updateUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  return (
    <div className="login">
      <NavigationBar />
      <div className="content">
        <div className="presented-by-banner">
          Presented By:{' '}
          <img src={gogoLogo} alt="Gogo Logo" style={{ height: '30px', margin: '16px' }} />
        </div>
        <div className="log-in-wrapper">
          <img className="dashLogo" src="/logo.svg" alt="DASH logo" />

          {path === '/forgotpassword' ? (
            <ForgotPassword username={username} handleUsernameChange={e => updateUsername(e)} />
          ) : (
            <LoginForm
              onSubmit={authenticate}
              formSubmitting={formSubmitting}
              handleUsernameChange={updateUsername}
            />
          )}
        </div>
      </div>
      <div className="footer">
        Copyright &copy; {new Date().getFullYear()} Gogo Inc. and its subsidiaries. All rights
        reserved
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    logout: () => dispatch(logout()),
    loginAs: (
      name: string,
      accountList: JSMap<{ accountId: string; accountName: string }[]>,
      token: string,
      email: string,
      cob_unverified: boolean
    ) => {
      dispatch(loginAs(name, accountList, token, email, cob_unverified));
    },
    setMessage: (err: { type: string; message: string[]; icon: string }) =>
      dispatch(setMessage(err)),
  },
});

export default connect(null, mapDispatchToProps)(Login);
