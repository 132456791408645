import {
  AppMapShape,
  // eslint-disable-next-line import/no-unresolved
} from '@gogoair/utils/build/types/Globals/src/types';
import { ThunkDispatch } from 'redux-thunk';
import history from '../history';
import logger from '../logger';
import { Action, ActionType, AuthSuccessAction } from '../types/global_reducer';
import ggDASHConfig from '../ggDASHConfig';
import { apiGetInternalAccounts, apiForgotPassword } from './api';
import indexListBy from '../lib/indexListBy';
import { ReduxState } from '../types/redux_types';
import { JSMap } from '../types/javascript_object';

const isSuccess = (status: number) => status === 200 || status === 201;
const isError = (status: number) => status !== 404 && status !== 401 && status !== 403;

export const loginAs =
  (
    name: string,
    accountList: JSMap<{ accountId: string; accountName: string }[]>,
    token: string,
    email: string,
    cob_unverified: boolean
  ) =>
  async (dispatch: ThunkDispatch<ReduxState, void, Action>): Promise<void> => {
    ggDASHConfig.currentUser = name;
    const accountAppLength = accountList && Object.keys(accountList).length;
    const isInternal = accountList && accountList['*'] && accountList['*'].length > 0;

    if (isInternal) {
      try {
        const internalAccounts = await apiGetInternalAccounts();
        if (isSuccess(internalAccounts.status) && internalAccounts.results != null) {
          const apps = Object.keys(accountList)
            .map(app => app.toLowerCase())
            .filter((value, index, array) => array.indexOf(value) === index); // get unique entries
          const authSuccessAction: AuthSuccessAction = {
            type: ActionType.AUTH_SUCCESS,
            payload: {
              name,
              apps: new Set(apps),
              token,
              accounts: indexListBy({ '*': internalAccounts.results }, 'accountId') as unknown as [
                string[],
                JSMap<AppMapShape>
              ],
              email,
              isInternal,
              cob_unverified,
            },
          };
          dispatch(authSuccessAction);
          dispatch({ type: ActionType.DELETE_ALL_MESSAGES, payload: null });
        } else {
          if (isError(internalAccounts.status)) {
            logger.error(
              `${ggDASHConfig.currentUser} status: [${internalAccounts.status}] action.setAccounts Unable to get internal accounts for name:${name} results:${internalAccounts}`
            );
          } else {
            logger.warn(
              `${ggDASHConfig.currentUser} status: [${internalAccounts.status}] action.setAccounts Unable to get internal accounts for name:${name} results:${internalAccounts}`
            );
          }
          dispatch({
            type: ActionType.SET_MESSAGE,
            payload: {
              type: 'alert',
              message: [internalAccounts.results as unknown as string],
              icon: 'fa-times',
            },
          });
          history.replace('/');
        }
      } catch (err) {
        logger.error(`${ggDASHConfig.currentUser} action.setAccounts error: ${err}`);
        dispatch({
          type: ActionType.SET_MESSAGE,
          payload: { type: 'alert', message: ['Problem getting accounts'], icon: 'fa-times' },
        });
      }
    } else {
      // Must be an external user
      const apps = Object.keys(accountList)
        .map(app => app.toLowerCase())
        .filter((value, index, array) => array.indexOf(value) === index); // get unique entries
      dispatch({
        type: ActionType.AUTH_SUCCESS,
        payload: {
          name,
          apps: new Set(apps),
          token,
          accounts: indexListBy(accountList, 'accountId') as unknown as [
            string[],
            JSMap<AppMapShape>
          ],
          email,
          cob_unverified,
          isInternal: false,
        },
      });
      dispatch({ type: ActionType.DELETE_ALL_MESSAGES, payload: null });
    }

    // Redirect users to the URL they were trying to go to before logging in
    const savedURL = localStorage.getItem('savedURL');
    if (savedURL) {
      localStorage.removeItem('savedURL');
      history.push(savedURL);
    }

    // We only want to redirect if the user is logging in
    if (history.location && history.location.pathname === '/login') {
      // Decide where to redirect based on apps
      // send to SSO if user has access to more than one app
      // otherwise send them directly to app they have access to

      let path = '/';

      if (accountAppLength === 1) {
        // take them to SSO view if they are INTERNAL
        if (isInternal) {
          path = `/${ggDASHConfig.apps.sso}`;
        } else {
          let app = Object.keys(accountList)[0];
          if (app === 'servicerequest') {
            app = 'service-request';
          }
          if (app === 'onboarding') {
            app = 'customer-onboarding';
          }
          if (app === 'groundportal') {
            app = 'dashboard';
          }
          path = `/${app}`;
        }
      } else if (accountAppLength === 2 && accountList.onboarding) {
        if (isInternal) {
          path = `/${ggDASHConfig.apps.sso}`;
        } else {
          // find the app that is not onboarding and redirect them there
          let app = Object.keys(accountList).filter(acct => acct !== 'onboarding')[0];
          if (app === 'servicerequest') {
            app = 'service-request';
          }
          if (app === 'groundportal') {
            app = 'dashboard';
          }
          path = `/${app}`;
        }
      } else if (accountAppLength > 1) {
        path = `/${ggDASHConfig.apps.sso}`;
      } else if (cob_unverified) {
        path = `/customer-onboarding`;
      }
      history.push(path);
    }
  };

export const logout =
  () =>
  (dispatch: ThunkDispatch<ReduxState, void, Action>): void => {
    // NOTE: caller is responsible to clear storage (local and session) and changing history.
    // IMPORTANT: used by auth0 login. don't put any history modification or storage removal here
    // as it will screw up the crappy auth0 login procedure. /v
    dispatch({ type: ActionType.AUTH_LOGOUT, payload: null });
    dispatch({ type: ActionType.DELETE_ALL_MESSAGES, payload: null });
  };

export const resetPassword =
  (email: string) => (dispatch: ThunkDispatch<ReduxState, void, Action>) => {
    return apiForgotPassword(email)
      .then((resp: { status: number }) => {
        if (resp.status === 200) {
          dispatch({
            type: ActionType.SET_MESSAGE,
            payload: {
              type: 'message',
              message: [`We've sent a password reset email to ${email}.`],
              icon: 'fa-check',
            },
          });
        } else {
          logger.error(
            `${email} status: [${resp.status}] Problem resetting password for: [${email}]`
          );
          dispatch({
            type: ActionType.SET_MESSAGE,
            payload: {
              type: 'alert',
              status: resp.status,
              message: [`Problem resetting password for ${email}`],
              icon: 'fa-times',
            },
          });
        }
      })
      .catch((ex: Error) => {
        logger.error(`${ggDASHConfig.currentUser} action.resetPassword resetPassword(): ${ex}`);
        dispatch({
          type: ActionType.SET_MESSAGE,
          payload: {
            type: 'alert',
            message: [`Problem resetting password for ${email}`],
            icon: 'fa-times',
          },
        });
      });
  };
