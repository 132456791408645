export const REQUIRED = 'Required';
export const INVALID_NAME = 'A-Z, 0-9, hyphens only, and length greater than 1.';

export const isValidEntry = (entry: string): boolean =>
  /^[A-Za-z0-9._%+-][A-Za-z0-9.-]/i.test(entry);

export const inputValidate = (values: { title: string }): { title?: string; body?: string } => {
  const errors: { title?: string; body?: string } = {};

  if (!isValidEntry(values.title)) {
    errors.title = INVALID_NAME;
  }
  return errors;
};

export const textboxValidate = (values: { body: string }): { title?: string; body?: string } => {
  const errors: { title?: string; body?: string } = {};

  if (!isValidEntry(values.body)) {
    errors.body = INVALID_NAME;
  }
  return errors;
};

const validate = (values: { title: string; body: string }): { title?: string; body?: string } => {
  const errors: { title?: string; body?: string } = {};

  if (!values.title) {
    errors.title = REQUIRED;
  }

  if (!isValidEntry(values.title)) {
    errors.title = INVALID_NAME;
  }

  if (!values.body) {
    errors.body = REQUIRED;
  }

  if (!isValidEntry(values.body)) {
    errors.body = INVALID_NAME;
  }

  return errors;
};

export default validate;
