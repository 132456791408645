import React from 'react';
import { Link } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import './index.css';
import Tile from '../../components/Tile';
import Footer from '../../components/Footer';
import groundPortalLogo from './connectivity.svg';
import activations from './activations.svg';
import serviceAgreements from './service.svg';
import userProfiles from './users.svg';
import billing from './billing.svg';
import mix from './mix.svg';
import serviceRequest from './serviceRequest.svg';
import maintenance from './equipment-maintenance.svg';
// import customerOnboarding from './customer-onboarding.svg';
import ggDASHConfig from '../../ggDASHConfig';
import { JSMap } from '../../types/javascript_object';

interface ViewProps {
  accountId: string;
  apps?: Set<string>;
  flags: JSMap<boolean | number>;
}

const View = ({ accountId, apps, flags }: ViewProps) => {
  // Activation Flags are enum
  const activationsFlag = flags.activations;
  const noActivations = activationsFlag === 4;
  const onlyAllowedActivations2 = activationsFlag === 2 && !noActivations;
  const allowedActivations2 = (activationsFlag === 2 || activationsFlag === 3) && !noActivations;

  // These remaining flags are boolean
  const billingFlag = flags.billing;
  const connectivityInsightsFlag = flags.connectivityInsights;
  // @NOTE: there's an odd legacy flag with LD
  const mixFlag = Boolean(flags.mix);
  const ssaFlag = flags.ssa;
  const userManagementFlag = flags.userManagement;
  const maintenanceFlag = flags.ota;
  const serviceRequestFlag = flags.serviceRequest;
  // const customerOnboardingFlag = flags.customerOnboarding;

  return (
    <div className="SSO-container">
      <div className="inner-sso">
        {connectivityInsightsFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.groundportal)) && (
            <Link
              to={accountId ? `/dashboard/a/${accountId}/aircraft/ALL` : '/dashboard'}
              className="link"
              data-testid="connectivityInsights"
            >
              <Tile
                showTile
                image={groundPortalLogo}
                content="Connectivity Insights"
                iconBackgroundColor="#e80029"
              />
            </Link>
          )}
        {allowedActivations2 &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.activation)) && (
            <Link to="/activations" className="link" data-testid="activations">
              <Tile
                showTile
                image={activations}
                content={onlyAllowedActivations2 ? 'Activations' : 'Activations II'}
                iconBackgroundColor="#40b4dc"
              />
            </Link>
          )}
        {ssaFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.ssa)) && (
            <Link to="/ssa" className="link" data-testid="ssa">
              <Tile
                showTile
                image={serviceAgreements}
                content="Service Agreements"
                iconBackgroundColor="#40b4dc"
              />
            </Link>
          )}
        {serviceRequestFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.servicerequest)) && (
            <Link to="/service-request" className="link" data-testid="serviceRequest">
              <Tile
                showTile
                image={serviceRequest}
                content="RMA Requests"
                iconBackgroundColor="#40b4dc"
              />
            </Link>
          )}
        {billingFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.billing)) && (
            <Link to="/billing" className="link" data-testid="billing">
              <Tile
                showTile
                image={billing}
                content="Account Management &amp; Billing"
                iconBackgroundColor="#234466"
              />
            </Link>
          )}
        {mixFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.mix)) && (
            <Link to="/mix" className="link" data-testid="mix">
              <Tile showTile image={mix} content="Mix" iconBackgroundColor="#234466" />
            </Link>
          )}
        {userManagementFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.admin)) && (
            <Link to="/admin" className="link" data-testid="userManagement">
              <Tile
                showTile
                image={userProfiles}
                content="User Management"
                iconBackgroundColor="#234466"
              />
            </Link>
          )}
        {maintenanceFlag &&
          apps &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.maintenance)) && (
            <Link to="/ota" className="link" data-testid="maintenance">
              <Tile
                showTile
                image={maintenance}
                content="Equipment Maintenance"
                iconBackgroundColor="#234466"
              />
            </Link>
          )}
        {/* {customerOnboardingFlag &&
          (apps.has(ggDASHConfig.apps.all) || apps.has(ggDASHConfig.apps.onboarding)) && (
            <Link
              to="/customer-onboarding"
              name="customer-onboarding"
              className="link"
              data-testid="customer-onboarding"
            >
              <Tile
                showTile
                image={customerOnboarding}
                content="Customer Onboarding"
                iconBackgroundColor="#234466"
              />
            </Link>
          )} */}
      </div>
      <Footer />
    </div>
  );
};

export default withLDConsumer()(View);
