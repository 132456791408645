// eslint-disable-next-line import/no-unresolved
import {
  AppMapShape,
  // eslint-disable-next-line import/no-unresolved
} from '@gogoair/utils/build/types/Globals/src/types';
import { JSMap } from './javascript_object';

export interface Note {
  timestamp: string;
  title: string;
  body: string;
}

export interface GlobalState {
  userDetails: {
    allAccountIds?: string[];
    allAccounts?: JSMap<AppMapShape>;
    isInternal?: boolean;
    name?: string | null;
    apps?: Set<string>;
    token?: string;
    email?: string;
    cob_unverified?: boolean;
  };
  selectedAccountId?: string | null;
  selectedAccountName?: string | null;
  tailSearchResults: null;
  searchFieldValue?: string;
  isAuthenticated: boolean;
  pageRefreshLoading: boolean;
  debug: boolean;
  pageMessage?: {
    type?: string;
    message?: string[];
    icon?: string;
  };
  notes: Note[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accountAircraft: any[]; // Can't figure out what the type should be because it'd never used
  accountAircraftIds: string[];
}

// eslint-disable-next-line no-shadow
export enum ActionType {
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
  DELETE_ALL_MESSAGES = 'DELETE_ALL_MESSAGES',
  PAGE_REFRESH_LOADING = 'PAGE_REFRESH_LOADING',
  SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT',
  SET_TAIL_SEARCH_RESULTS = 'SET_TAIL_SEARCH_RESULTS',
  SET_SEARCH_FIELD_VALUE = 'SET_SEARCH_FIELD_VALUE',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_AIRCRAFT = 'SET_AIRCRAFT',
  SET_NOTES = 'SET_NOTES',
  SET_NOTE = 'SET_NOTE',
  EDIT_NOTE = 'EDIT_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  LOADING_UI = 'LOADING_UI',
}

export interface AuthSuccessAction {
  type: ActionType.AUTH_SUCCESS;
  payload: {
    accounts: [string[], JSMap<AppMapShape>];
    isInternal: boolean;
    name: string | null;
    apps: Set<string>;
    token: string;
    email: string;
    cob_unverified: boolean;
  };
}
export interface AuthLogoutAction {
  type: ActionType.AUTH_LOGOUT;
}
export interface DeleteMessageAction {
  type: ActionType.DELETE_MESSAGE;
}
export interface DeleteAllMessagesAction {
  type: ActionType.DELETE_ALL_MESSAGES;
}
export interface PageRefreshLoadingAction {
  type: ActionType.PAGE_REFRESH_LOADING;
}

export interface SetSelectedAction {
  type: ActionType.SET_SELECTED_ACCOUNT;
  payload: {
    accountId?: string;
    selectedApp?: string;
  };
}

export interface SetTailSearchResultAction {
  type: ActionType.SET_TAIL_SEARCH_RESULTS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any; // Can't figure out what the type should be because it'd never used
}

export interface SetSearchFieldValueAction {
  type: ActionType.SET_SEARCH_FIELD_VALUE;
  payload?: string;
}

export interface SetMessageAction {
  type: ActionType.SET_MESSAGE;
  payload: {
    type: string;
    message: string[];
    icon: string;
  };
}

export interface SetAircraftAction {
  type: ActionType.SET_AIRCRAFT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any[]; // Can't figure out what the type should be because it'd never used
}

export interface SetNotesAction {
  type: ActionType.SET_NOTES;
  payload: Note[];
}

export interface SetNoteAction {
  type: ActionType.SET_NOTE;
  payload: Note;
}

export interface EditNoteAction {
  type: ActionType.EDIT_NOTE;
  payload: {
    newNote: Note;
  };
}

export interface DeleteNoteAction {
  type: ActionType.DELETE_NOTE;
  payload: {
    noteId: string;
  };
}

export interface LoadingUiAction {
  type: ActionType.LOADING_UI;
  payload: boolean;
}

export type Action =
  | AuthSuccessAction
  | AuthLogoutAction
  | DeleteMessageAction
  | DeleteAllMessagesAction
  | PageRefreshLoadingAction
  | SetSelectedAction
  | SetTailSearchResultAction
  | SetSearchFieldValueAction
  | SetMessageAction
  | SetAircraftAction
  | SetNotesAction
  | SetNoteAction
  | EditNoteAction
  | DeleteNoteAction
  | LoadingUiAction;
