/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
  Usage:
    import ggDASHConfig from './ggDASHConfig';

    ggDASHConfig.set('veg', 'carrot')
    ggDASHConfig.get('veg') -> 'carrot'
    ggDASHConfig.unset('veg') -> undefined
*/

// read the url to see what env we're running on
export const deployedEnvironment = urlHost => {
  if (urlHost.match(/^dash.dev.gogoair/)) return 'development';
  if (urlHost.match(/^dash.stage.gogoair/)) return 'stage';
  if (urlHost.match(/^dash.gogoair/)) return 'production';
  return 'development';
};

const ggDASHConfig = {
  // default values
  deployedEnv: deployedEnvironment(document.location.host),
  utcDates: false,
  currentUser: '',
  masterAccountId: '*',
  apps: {
    groundportal: 'groundportal',
    admin: 'admin',
    ssa: 'ssa',
    activation: 'activation',
    billing: 'billing',
    sso: 'SSO',
    mix: 'mix',
    maintenance: 'maintenance',
    servicerequest: 'servicerequest',
    onboarding: 'onboarding',
    all: '*',
  },

  set(k, v) {
    //  To delete set to undefined.
    if (v === undefined) {
      delete this[k];
      return undefined;
    }

    if (v.length > 0) {
      if (v === 'true') {
        this[k] = true;
      } else if (v === 'false') {
        this[k] = false;
      } else {
        this[k] = v;
      }
    } else {
      //  existence means true
      this[k] = true;
    }

    //  Always return the final value.
    return this[k];
  },

  get(k) {
    return this[k];
  },

  unset(k) {
    return this.set(k);
  },
};

export default ggDASHConfig;
