import { JSMap } from '../types/javascript_object';

const createAcctMap = (
  list: JSMap<JSMap<string>[]>,
  appName: string,
  searchKey: string
): { acctMap: JSMap<JSMap<string>>; keys: string[] } => {
  const acctMap: JSMap<JSMap<string>> = {};
  const keys: string[] = [];
  list[appName].forEach((acct: JSMap<string>) => {
    acctMap[acct[searchKey]] = acct;
    keys.push(acct[searchKey]);
  });
  return { acctMap, keys };
};

const createIndex = (
  list: JSMap<
    {
      accountId: string;
      accountName: string;
    }[]
  >,
  searchKey: string
) => {
  const allApps: JSMap<JSMap<JSMap<string>>> = {};
  let allKeys: string[] = [];
  Object.keys(list).forEach(app => {
    const { acctMap, keys } = createAcctMap(list, app, searchKey);
    allKeys = keys;
    allApps[app] = acctMap;
  });
  return { allApps, allKeys };
};

const indexListBy = (
  list: JSMap<{ accountId: string; accountName: string }[]>,
  searchKey: string
): [string[], JSMap<JSMap<JSMap<string>>>] => {
  const { allApps, allKeys } = createIndex(list, searchKey);
  return [allKeys, allApps];
};

export default indexListBy;
