/* eslint-disable no-async-promise-executor */

import 'whatwg-fetch';
import getBearerToken from './getBearerToken';
import { Note } from '../types/global_reducer';
// import logger from '../logger';

const postApi = <T>(
  url: string,
  params: { username: string; password: string }
): Promise<{ status: number; results?: T }> =>
  new Promise(async resolve => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        ...getBearerToken().headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const fetchResults = { status: res.status, results: undefined };

    if (res.ok) {
      const jsonBody = await res.json();
      fetchResults.results = jsonBody;
    }
    return resolve(fetchResults);
  });

const postPasswordResetApi = <T>(
  url: string,
  params: { email: string }
): Promise<{ status: number; results?: T }> =>
  new Promise(async resolve => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const fetchResults = { status: res.status };

    return resolve(fetchResults);
  });

const getApiData = <T>(url: string): Promise<{ status: number; results?: T }> =>
  new Promise(async resolve => {
    const res = await fetch(url, getBearerToken());
    const fetchResults: { status: number; results?: T } = {
      status: res.status,
      results: undefined,
    };
    if (res.ok) {
      const jsonBody: T = await res.json();
      fetchResults.results = jsonBody;
    }
    return resolve(fetchResults);
  });

const getUserData = <T>(
  url: string,
  accessToken: string | string[]
): Promise<{ status: number; results?: T }> =>
  new Promise(async resolve => {
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    });
    const fetchResults: { status: number; results?: T } = {
      status: res.status,
      results: undefined,
    };

    if (res.ok) {
      const jsonBody = await res.json();
      fetchResults.results = jsonBody;
    }
    return resolve(fetchResults);
  });

const getReleaseNotesFromS3 = <T>(url: string): Promise<{ status: number; results?: T }> =>
  new Promise(async resolve => {
    const res = await fetch(url);
    const fetchResults: { status: number; results?: T } = {
      status: res.status,
      results: undefined,
    };
    if (res.ok) {
      const jsonBody = await res.json();
      fetchResults.results = jsonBody;
    }
    return resolve(fetchResults);
  });

const putReleaseNoteInS3 = (
  putUrl: string,
  getUrl: string,
  params: Note | Note[]
): Promise<number> =>
  new Promise(async resolve => {
    // bodyParams set to params passed in by default
    let bodyParams: Note[] = params as Note[];
    // only get all current notes if creating a new note, not editing a note
    // params size is 3 because { 1: title, 2: timestamp, 3: body }
    if (Object.keys(params).length === 3) {
      // const getData = await fetch(parsedUrl.getURL);
      const getData = await fetch(getUrl);
      const getResults: { status: number; results?: Note[] } = {
        status: getData.status,
        results: undefined,
      };
      if (getData.ok) {
        const jsonBody: Note[] = await getData.json();
        getResults.results = jsonBody;
      }
      // combine existing notes and new note, then
      // reassign bodyParams
      bodyParams = [...(getResults.results ? getResults.results : []), params as Note];
    }
    // const res = await fetch(parsedUrl.putURL, {
    const res = await fetch(putUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyParams),
    });
    const putResults = { status: res.status };
    return resolve(putResults.status);
  });

const getS3PresignedUrls = (url: string): Promise<string> =>
  new Promise(async resolve => {
    const res = await fetch(url, getBearerToken());
    if (res.ok) {
      await res.text().then(response => resolve(response));
    }
  });

export {
  postApi,
  postPasswordResetApi,
  getApiData,
  getUserData,
  getReleaseNotesFromS3,
  putReleaseNoteInS3,
  getS3PresignedUrls,
};
