import React from 'react';
import './index.css';

interface TileProps {
  showTile: boolean;
  image: string;
  content: string;
  iconBackgroundColor: string;
}

const Tile = ({ showTile, image, content, iconBackgroundColor }: TileProps): JSX.Element => {
  if (!showTile) {
    return null;
  }
  return (
    <div className="tile-component">
      <div className="tile-selector">
        <div className="content-component">
          <h4 className="styled-caption">{content}</h4>
          <img className="image-component" src={image} alt={content} />
        </div>
        <div className="icon-background" style={{ backgroundColor: iconBackgroundColor }} />
      </div>
    </div>
  );
};

export default Tile;
