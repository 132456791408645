import { connect } from 'react-redux';
import View from './view';
import { ReduxState } from '../../types/redux_types';

const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.global.isAuthenticated,
  apps: state.global.userDetails.apps,
});

export default connect(mapStateToProps)(View);
