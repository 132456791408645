import { connect } from 'react-redux';
import View from './view';
import { ReduxState } from '../../types/redux_types';

// connect App to redux for profile/account info
const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.global.isAuthenticated,
  userDetails: state.global.userDetails,
  apps: state.global.userDetails.apps,
  selectedAccountId: state.global.selectedAccountId,
});

export default connect(mapStateToProps)(View);
