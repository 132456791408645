import { Action, ActionType } from '../../types/global_reducer';

export const setSearchFieldValue = (value?: string): Action => ({
  type: ActionType.SET_SEARCH_FIELD_VALUE,
  payload: value,
});
export const clearSelectedAccount = (): Action => ({
  type: ActionType.SET_SELECTED_ACCOUNT,
  payload: {},
});
