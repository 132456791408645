import React from 'react';
import { Grid, Card } from '@gogoair/ba-ui-kit';
import Footer from '../../components/Footer';

import './index.css';

const NotFound = (): JSX.Element => (
  <div className="fourOhFour" data-test="fourOhFour">
    <div className="content">
      <Grid>
        <Card>
          <span data-test="gogo-tech-support">
            <div className="ui-panelrow">
              <h3 className="title">Page or Result Data Not Found</h3>
            </div>
            <React.Fragment>
              <div className="ui-panelrow">
                There was a problem accessing the requested resource.
              </div>
              <div className="ui-panelrow">
                Please verify you are using the correct account and URL.
              </div>
            </React.Fragment>
          </span>
        </Card>
      </Grid>
    </div>
    <Footer />
  </div>
);

export default NotFound;
