import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import history from '../../history';
import { logout } from '../../actions';
import { ReduxState } from '../../types/redux_types';
import { Action } from '../../types/global_reducer';

interface LogoutProps {
  actions: {
    logout: () => void;
  };
}

const Logout = ({ actions }: LogoutProps) => {
  useEffect(() => {
    actions.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    logout: () => {
      localStorage.clear();
      sessionStorage.clear();

      dispatch(logout());

      history.push('/');
    },
  },
});

export default connect(null, mapDispatchToProps)(Logout);
