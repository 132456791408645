import React, { useState } from 'react';
import ActionsGroup from './components/ActionsGroup';
import { utcToDayMonthYear } from '../../../../lib/timeUtils';
import isValid from '../../../../lib/isValid';
import { inputValidate, textboxValidate } from '../NewNoteForm/validate';
import { Note } from '../../../../types/global_reducer';

const stopPropagation = (
  e:
    | React.MouseEvent<HTMLInputElement, MouseEvent>
    | React.MouseEvent<HTMLTextAreaElement, MouseEvent>
) => e.stopPropagation();

interface NotesTableRowsProps {
  notes: Note[];
  isInternal: boolean;
}

const NotesTableRows = ({ notes, isInternal }: NotesTableRowsProps): JSX.Element | null => {
  let result;
  if (isValid(notes) && notes.length > 0) {
    result = (
      <div className="note-container">
        {notes.map((note: Note) => (
          <NotesTable note={note} isInternal={isInternal} key={note.timestamp} />
        ))}
      </div>
    );
  } else {
    result = null;
  }
  return result;
};

interface NotesTableProps {
  note: Note;
  isInternal: boolean;
}

const NotesTable = ({ note, isInternal }: NotesTableProps): JSX.Element => {
  const [isNoteEditable, setIsNoteEditable] = useState(false);
  const [title, setTitle] = useState(note.title);
  const [body, setBody] = useState(note.body);
  const [inputError, setInputError] = useState<null | {
    title?: string;
    body?: string;
  }>(null);
  const [textareaError, setTextareaError] = useState<null | {
    title?: string;
    body?: string;
  }>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const setEditMode = () => {
    if (isNoteEditable) {
      setIsNoteEditable(false);
    } else {
      setIsNoteEditable(true);
    }
  };

  const cancelEdit = () => {
    setIsNoteEditable(false);
    setTitle(note.title);
    setBody(note.body);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = { title: e.target.value };
    const iError = inputValidate(input);
    const validInput = iError && isValid(iError.title) ? iError.title : null;
    const validTextarea = textareaError && isValid(textareaError.body) ? textareaError.body : null;
    if ((validInput && isValid(iError.title)) || (validTextarea && isValid(textareaError?.body))) {
      setTitle(e.target.value);
      setInputError(iError);
      setIsDisabled(true);
    } else {
      setTitle(e.target.value);
      setInputError(iError);
      setIsDisabled(true);
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = { body: e.target.value };
    const taError = textboxValidate(input);
    if (isValid(taError) && taError.body) {
      setBody(e.target.value);
      setTextareaError(taError);
      setIsDisabled(true);
    } else if (isValid(inputError) && inputError?.title) {
      setBody(e.target.value);
      setTextareaError(taError);
      setIsDisabled(true);
    } else {
      setBody(e.target.value);
      setTextareaError(taError);
      setIsDisabled(false);
    }
  };

  return (
    <div key={note.timestamp} className="note">
      {isNoteEditable ? (
        <div key={note.timestamp} className="note-title-and-body">
          <span className="note-error">{inputError ? inputError.title : null}</span>
          <input value={title} onClick={stopPropagation} onChange={handleInputChange} />
          <span className="note-error">{textareaError ? textareaError.body : null}</span>
          <textarea value={body} onClick={stopPropagation} onChange={handleTextareaChange} />
        </div>
      ) : (
        <div key={note.timestamp} className="note-title-and-body">
          <h1>
            {title}
            <span className="note-timestamp">{utcToDayMonthYear(note.timestamp)}</span>
          </h1>
          <p>{body}</p>
        </div>
      )}
      {isInternal ? (
        <ActionsGroup
          note={note}
          title={title}
          body={body}
          isDisabled={isDisabled}
          setEditMode={setEditMode}
          cancelEdit={cancelEdit}
        />
      ) : null}
    </div>
  );
};

export default NotesTableRows;
