import { ThunkDispatch } from 'redux-thunk';
import logger from '../../../logger';
import { Action, ActionType, Note } from '../../../types/global_reducer';
import {
  apiGetPresignedUrl,
  apiPutPresignedUrl,
  apiGetReleaseNotes,
  apiPutReleaseNote,
} from './api';

import ggDASHConfig from '../../../ggDASHConfig';
import { ReduxState } from '../../../types/redux_types';

// get all notes
export const getNotes =
  (accountId: string) =>
  async (dispatch: ThunkDispatch<ReduxState, void, Action>): Promise<void> => {
    try {
      const url = await apiGetPresignedUrl(accountId);
      const data = await apiGetReleaseNotes(url);
      dispatch({ type: ActionType.SET_NOTES, payload: data.results });
    } catch (ex) {
      logger.warn(`${ggDASHConfig.currentUser} action.getNotes ex: ${ex}`);
    }
  };
// create note
export const setNote =
  (accountId: string, params: Note) =>
  async (dispatch: ThunkDispatch<ReduxState, void, Action>): Promise<void> => {
    try {
      const putUrl = await apiPutPresignedUrl(accountId).then(async (response: string) => response);
      const getUrl = await apiGetPresignedUrl(accountId).then(async (response: string) => response);
      const data = await apiPutReleaseNote(putUrl, getUrl, params);
      dispatch({
        type: ActionType.SET_MESSAGE,
        payload: {
          type: 'message',
          message: ['Release note successfully created.'],
          icon: 'fa-check',
        },
      });
      if (data === 200) {
        dispatch({ type: ActionType.SET_NOTE, payload: params });
      } else {
        dispatch({
          type: ActionType.SET_MESSAGE,
          payload: { type: 'alert', message: [String(data)], icon: 'fa-times' },
        });
      }
    } catch (ex) {
      logger.warn(`${ggDASHConfig.currentUser} action.setNote ex: ${ex}`);
      dispatch({
        type: ActionType.SET_MESSAGE,
        payload: { type: 'alert', message: ['Problem creating note'], icon: 'fa-times' },
      });
    }
  };
// edit note
export const editNote =
  (accountId: string, newNote: Note) =>
  async (dispatch: ThunkDispatch<ReduxState, void, Action>): Promise<void> => {
    try {
      const putUrl = await apiPutPresignedUrl(accountId).then(async (response: string) => response);
      const getUrl = await apiGetPresignedUrl(accountId).then(async (response: string) => response);
      const data = await apiGetReleaseNotes(getUrl);
      const filtered = data.results.map((n: Note) => {
        if (n.timestamp === newNote.timestamp) {
          return newNote;
        }
        return n;
      });
      apiPutReleaseNote(putUrl, getUrl, filtered[0]);
      dispatch({
        type: ActionType.SET_MESSAGE,
        payload: {
          type: 'message',
          message: ['Release note successfully updated.'],
          icon: 'fa-check',
        },
      });
      dispatch({ type: ActionType.EDIT_NOTE, payload: { newNote } });
    } catch (ex) {
      logger.warn(`${ggDASHConfig.currentUser} action.editNote ex: ${ex}`);
    }
  };
// delete note
export const deleteNote =
  (accountId: string, noteId: string, note: Note) =>
  async (dispatch: ThunkDispatch<ReduxState, void, Action>): Promise<void> => {
    try {
      const putUrl = await apiPutPresignedUrl(accountId).then(async (response: string) => response);
      const getUrl = await apiGetPresignedUrl(accountId).then(async (response: string) => response);
      const data = await apiGetReleaseNotes(getUrl);
      const filtered = data.results.filter((n: Note) => n.timestamp !== note.timestamp);
      await apiPutReleaseNote(putUrl, getUrl, filtered);
      dispatch({
        type: ActionType.SET_MESSAGE,
        payload: {
          type: 'message',
          message: ['Release note successfully deleted.'],
          icon: 'fa-check',
        },
      });
      dispatch({ type: ActionType.DELETE_NOTE, payload: { noteId } });
    } catch (ex) {
      logger.warn(`${ggDASHConfig.currentUser} action.deleteNote ex: ${ex}`);
    }
  };
