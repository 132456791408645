import React, { useEffect, useState } from 'react';
import NewNoteForm from './components/NewNoteForm';
import NotesTableRows from './components/NotesTable';
import Footer from '../../components/Footer';

import './index.css';
import { Note } from '../../types/global_reducer';

interface NotesMainProps {
  notes: Note[];
  isInternal?: boolean;
  accountId?: string | null;
  actions: {
    getNotes: (accountId?: string | null) => void;
    createNote: (accountId: string, params: Note) => void;
  };
  initialize: (noteInfo: { title: string; body: string }) => void;
  dirty: boolean;
}

const NotesMain = ({
  notes,
  isInternal,
  actions,
  accountId,
  initialize,
  dirty,
}: NotesMainProps): JSX.Element => {
  const [showReleaseNoteForm, setShowReleaseNoteForm] = useState(false);

  const getNotes = () => {
    actions.getNotes(accountId);
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCreateForm = () => {
    if (showReleaseNoteForm === true) {
      setShowReleaseNoteForm(false);
    } else {
      setShowReleaseNoteForm(true);
    }
  };

  const handleSubmit = (noteInfo: { title: string; body: string }) => {
    // Check if the form values have changed since the last submit
    // The dirty boolean (signifies if the form has changed from its initial values) is established by redux-form by comparing the currently entered values to the initial form values
    // After we identify the form as having been changed, we will send the new form values to replace the original initial form values so redux-form is always comparing the current form values against the last submitted form values
    if (dirty) {
      // Set the initial values for the form on the redux store (This is used by redux form to compare the current values to the initial values so we can determine if there are new values since the last submit)
      initialize(noteInfo);
    }
    const timestamp = {
      timestamp: Date.now(),
    };
    const combined: Note = { ...noteInfo, timestamp: String(timestamp.timestamp) };
    actions.createNote(accountId || '', combined);
    toggleCreateForm();
  };

  return (
    <div className="notes">
      <div className="content">
        {isInternal && showReleaseNoteForm === true ? (
          <NewNoteForm onSubmit={handleSubmit} hideForm={toggleCreateForm} />
        ) : null}
        {isInternal && showReleaseNoteForm === false ? (
          <button className="note-create" type="button" onClick={toggleCreateForm}>
            Create Note
          </button>
        ) : null}
        <NotesTableRows notes={notes} isInternal={isInternal || false} />
      </div>
      <Footer />
    </div>
  );
};

export default NotesMain;
