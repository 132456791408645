/* eslint-disable @typescript-eslint/no-unused-vars */
import { Reducer, Store } from 'redux';

export interface RegistryMap {
  [key: string]: Reducer;
}

export class ReducerRegistry {
  emitChange?: (reducers: RegistryMap) => Store;

  reducers: RegistryMap;

  constructor() {
    this.emitChange = undefined;
    this.reducers = {};
  }

  getReducers(): RegistryMap {
    return { ...this.reducers };
  }

  register(name: string, reducer: Reducer): Promise<{ store?: Store }> {
    this.reducers = { ...this.reducers, [name]: reducer };
    return new Promise(resolve => {
      if (this.emitChange) {
        const store = this.emitChange(this.getReducers());
        resolve({ store });
      } else {
        resolve({});
      }
    });
  }

  setChangeListener(listener: (reducers: RegistryMap) => Store): void {
    this.emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
