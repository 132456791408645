import React from 'react';

const HelpIcon = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>HelpIcon</title>
    <desc>Created with Sketch.</desc>
    <g id="V2-Prototype" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Flight-Interval-Details"
        transform="translate(-1234.000000, -27.000000)"
        fill="#40b4dc"
        fillRule="nonzero"
      >
        <g id="help_outline_24px" transform="translate(1234.000000, 27.000000)">
          <path
            d="M10.8,19.2 L13.2,19.2 L13.2,16.8 L10.8,16.8 L10.8,19.2 Z M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 Z M12,21.6 C6.708,21.6 2.4,17.292 2.4,12 C2.4,6.708 6.708,2.4 12,2.4 C17.292,2.4 21.6,6.708 21.6,12 C21.6,17.292 17.292,21.6 12,21.6 Z M12,4.8 C9.348,4.8 7.2,6.948 7.2,9.6 L9.6,9.6 C9.6,8.28 10.68,7.2 12,7.2 C13.32,7.2 14.4,8.28 14.4,9.6 C14.4,12 10.8,11.7 10.8,15.6 L13.2,15.6 C13.2,12.9 16.8,12.6 16.8,9.6 C16.8,6.948 14.652,4.8 12,4.8 Z"
            id="help"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HelpIcon;
