import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';

import reducerRegistry, { RegistryMap } from './reducers/reducerRegistry';
import './reducers/global_reducer';

const combine = (reducers: RegistryMap) => combineReducers({ ...reducers, form });

const reducer = combine(reducerRegistry.getReducers());

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(combine(reducers));
  return store;
});

export default store;
