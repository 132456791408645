import React from 'react';

interface MessageBannerProps {
  actions: {
    messageClickHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  message?: {
    type?: string;
    icon?: string;
    message?: string[];
  };
}

const MessageBanner = ({ actions, message }: MessageBannerProps): JSX.Element => {
  const messageType = message ? message.type : '';
  const messageIcon = message ? message.icon : null;
  const messages = message ? message.message : null;
  return (
    <div>
      {messages &&
        messages.map(m => (
          <div key={m} className={messageType} data-test={messageType}>
            <button type="button" onClick={e => actions.messageClickHandler(e)}>
              <i className={`fas ${messageIcon}`} />
              {m}
              <i className="far fa-times-circle" />
            </button>
          </div>
        ))}
    </div>
  );
};

export default MessageBanner;
