/* eslint-disable */
import React from 'react';

const logo = (
  <svg width="82px" height="39px" viewBox="0 0 82 39" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Fleet-Overview" transform="translate(-43.000000, -21.000000)">
        <g id="Dash---Logo" transform="translate(43.000000, 21.000000)">
          <path
            d="M8.08964007,34.5600721 C11.5917247,34.5600721 13.8544174,31.8900007 13.8544174,28.4701546 C13.8544174,25.0803966 11.5917247,22.4103251 8.08964007,22.4103251 L4.83523327,22.4103251 L4.83523327,34.5600721 L8.08964007,34.5600721 Z M0,18.570217 C0,18.2698218 0.247677855,18 0.558027232,18 L8.12072514,18 C14.1025966,18 19,22.7097497 19,28.4701546 C19,34.2902503 14.1025966,39 8.12072514,39 L0.558027232,39 C0.247677855,39 0,38.7306635 0,38.4307536 L0,18.570217 Z"
            id="Fill-1"
          ></path>
          <path
            d="M30.2552999,31.9017589 L28.0002687,27.02112 L27.9705088,27.02112 L25.7447576,31.9017589 L30.2552999,31.9017589 Z M18.0595077,38.2311355 L27.3469913,18.325344 C27.4362709,18.1478402 27.6441101,18 27.8519493,18 L28.1485881,18 C28.3559473,18 28.5637865,18.1478402 28.6530662,18.325344 L37.9405497,38.2311355 C38.118629,38.6153285 37.88103,39 37.4360717,39 L34.1423246,39 C33.6080867,39 33.3709677,38.8229746 33.1040887,38.2607992 L32.0356129,35.9240636 L23.9644446,35.9240636 L22.8959688,38.290463 C22.7476493,38.6454707 22.4212506,39 21.8279729,39 L18.5639858,39 C18.1185475,39 17.8814284,38.6153285 18.0595077,38.2311355 Z"
            id="Fill-3"
          ></path>
          <path
            d="M39.297146,36.6468289 C39.0591407,36.4026645 38.9701892,36.1273618 39.1779029,35.7304711 L40.5458323,33.2552178 C40.7833568,32.8578329 41.2588866,32.8578329 41.5560326,33.0723417 C42.3883298,33.6521085 43.9043514,34.7523309 45.7771405,34.7523309 C46.9662053,34.7523309 47.8283134,33.9886994 47.8283134,32.9191211 C47.8283134,31.6355283 46.7282,30.8106086 44.7664595,29.8329626 C41.8829893,28.3971378 39,26.7472984 39,23.0803846 C39,20.0555144 41.2886973,17 45.8665728,17 C48.9572758,17 51.3354054,18.6191953 52.2268434,19.2913886 C52.5836109,19.5054032 52.5244702,20.0555144 52.4052272,20.2690346 L50.8896864,22.6222057 C50.6814919,22.9583024 50.1756706,23.2029611 49.5818594,22.8362203 C48.8976543,22.346903 47.1445891,21.1858866 45.5684652,21.1858866 C44.3207405,21.1858866 43.7557784,21.9801622 43.7557784,22.8663701 C43.7557784,23.8746602 44.9150325,24.66943 46.9955353,25.7083642 C49.6116702,27.0221069 53,28.51922 53,32.6749568 C53,35.8219092 50.3545352,39 45.985335,39 C42.1209946,39 39.9803895,37.3496664 39.297146,36.6468289"
            id="Fill-5"
          ></path>
          <path
            d="M56,18.5702038 C56,18.2698156 56.2703009,18 56.5702038,18 L60.1399224,18 C60.4699126,18 60.7096409,18.2698156 60.7096409,18.5702038 L60.7096409,26.1298932 L69.2903591,26.1298932 L69.2903591,18.5702038 C69.2903591,18.2698156 69.5300874,18 69.8600776,18 L73.4302815,18 C73.7296991,18 74,18.2698156 74,18.5702038 L74,38.4302815 C74,38.7301844 73.7296991,39 73.4302815,39 L69.8600776,39 C69.5300874,39 69.2903591,38.7301844 69.2903591,38.4302815 L69.2903591,30.5401165 L60.7096409,30.5401165 L60.7096409,38.4302815 C60.7096409,38.7301844 60.4699126,39 60.1399224,39 L56.5702038,39 C56.2703009,39 56,38.7301844 56,38.4302815 L56,18.5702038 Z"
            id="Fill-8"
          ></path>
          <path
            d="M4.08609226,6 C5.04236068,6 6.08931387,6.39030118 6.86325314,7.04737862 C6.96593324,7.12534492 6.97696895,7.25920272 6.88628419,7.34844126 L5.98663377,8.28403687 C5.895949,8.36200317 5.77071766,8.36200317 5.67955309,8.29530911 C5.24676126,7.9054776 4.74631572,7.76034756 4.18829255,7.76034756 C2.9479745,7.76034756 1.94612379,8.78565138 1.94612379,10.0110374 C1.94612379,11.2256209 2.95901021,12.2283802 4.19980807,12.2283802 C4.5299198,12.2283802 4.87154706,12.1837609 5.21317431,12.0499031 L5.21317431,11.4036283 L4.49585304,11.4036283 C4.3706217,11.4036283 4.27945713,11.3143897 4.27945713,11.2035461 L4.27945713,10.1002759 C4.27945713,9.97769037 4.3706217,9.88845183 4.49585304,9.88845183 L6.7840839,9.88845183 C6.8973199,9.88845183 6.98848447,9.9889626 6.98848447,10.1002759 L7,13.0972817 C7,13.1977925 6.95441771,13.2419421 6.90883542,13.2757588 C6.90883542,13.2757588 5.7366509,14 4.08609226,14 C1.82089245,14 0,12.2396524 0,10.0110374 C0,7.78289203 1.82089245,6 4.08609226,6"
            id="Fill-10"
          ></path>
          <path
            d="M12.9946063,12.2221181 C14.2182682,12.2221181 15.2196166,11.2221767 15.2196166,10.0110064 C15.2196166,8.78906387 14.2182682,7.77788186 12.9946063,7.77788186 C11.7817318,7.77788186 10.7803834,8.78906387 10.7803834,10.0110064 C10.7803834,11.2221767 11.7817318,12.2221181 12.9946063,12.2221181 M12.9946063,6 C15.2196166,6 17,7.78912242 17,10.0110064 C17,12.2333587 15.2196166,14 12.9946063,14 C10.769127,14 9,12.2333587 9,10.0110064 C9,7.78912242 10.769127,6 12.9946063,6"
            id="Fill-12"
          ></path>
          <path
            d="M23.0860923,6 C24.0423607,6 25.0893139,6.39030118 25.8637329,7.04737862 C25.9659332,7.12534492 25.9769689,7.25920272 25.8862842,7.34844126 L24.9866338,8.28403687 C24.895949,8.36200317 24.7707177,8.36200317 24.6795531,8.29530911 C24.2472411,7.9054776 23.7463157,7.76034756 23.1882925,7.76034756 C21.9479745,7.76034756 20.9461238,8.78565138 20.9461238,10.0110374 C20.9461238,11.2256209 21.9590102,12.2283802 23.1998081,12.2283802 C23.5299198,12.2283802 23.8715471,12.1837609 24.2131743,12.0499031 L24.2131743,11.4036283 L23.495853,11.4036283 C23.3706217,11.4036283 23.2794571,11.3143897 23.2794571,11.2035461 L23.2794571,10.1002759 C23.2794571,9.97769037 23.3706217,9.88845183 23.495853,9.88845183 L25.7840839,9.88845183 C25.8973199,9.88845183 25.9884845,9.9889626 25.9884845,10.1002759 L26,13.0972817 C26,13.1977925 25.9548975,13.2419421 25.9088354,13.2757588 C25.9088354,13.2757588 24.7366509,14 23.0860923,14 C20.8208925,14 19,12.2396524 19,10.0110374 C19,7.78289203 20.8208925,6 23.0860923,6"
            id="Fill-14"
          ></path>
          <path
            d="M31.9946063,12.2221181 C33.2182682,12.2221181 34.2200856,11.2221767 34.2200856,10.0110064 C34.2200856,8.78906387 33.2182682,7.77788186 31.9946063,7.77788186 C30.7817318,7.77788186 29.7803834,8.78906387 29.7803834,10.0110064 C29.7803834,11.2221767 30.7817318,12.2221181 31.9946063,12.2221181 M31.9946063,6 C34.2200856,6 36,7.78912242 36,10.0110064 C36,12.2333587 34.2200856,14 31.9946063,14 C29.769127,14 28,12.2333587 28,10.0110064 C28,7.78912242 29.769127,6 31.9946063,6"
            id="Fill-16"
          ></path>
          <path
            d="M72.7068664,14.2928244 C73.0977112,14.6832568 73.0977112,15.3167432 72.7068664,15.7071756 C72.3160215,16.0976081 71.6836162,16.0976081 71.2927713,15.7071756 C70.9024096,15.3167432 70.9024096,14.6832568 71.2927713,14.2928244 C71.6836162,13.9023919 72.3160215,13.9023919 72.7068664,14.2928244"
            id="Fill-18"
          ></path>
          <path
            d="M74.0619469,13 C72.9247788,11.9561686 71.0752212,11.9561686 69.9375615,13 L69,12.1389406 C70.653884,10.6203531 73.346116,10.6203531 75,12.1389406 L74.0619469,13 Z"
            id="Fill-20"
          ></path>
          <path
            d="M76.025984,11 C73.8058604,8.81104212 70.1941396,8.81104212 67.9745265,11 L67,10.0389327 C69.7571596,7.32035576 74.2433509,7.32035576 77,10.0389327 L76.025984,11 Z"
            id="Fill-22"
          ></path>
          <path
            d="M82,12.1282251 C82,9.85162141 81.0768041,7.67585297 79.4010309,6 L78.3268041,6.90657018 C79.7551546,8.33388564 80.5417526,10.1882122 80.5417526,12.1282251 C80.5417526,14.7201163 79.1391753,17.0118689 77,18.4131471 L77,20 C79.9814433,18.4396578 82,15.4988672 82,12.1282251"
            id="Fill-24"
          ></path>
          <path
            d="M77.0714938,2.65157519 C76.6671996,2.60005944 76.1669712,2.58916188 75.5913659,2.58916188 L74.1322848,0.00198137507 L73.6418456,0 L74.2468185,2.63820091 C73.6687659,2.6708936 73.1504275,2.7229047 72.7392808,2.76946701 L72.367291,2.29938577 L72.367291,2.2770953 C72.3614175,2.2770953 72.3560334,2.27808599 72.3501599,2.27808599 L72.0403315,1.88676441 L71.7128826,1.88478304 L71.8102852,2.30582524 C66.9049142,2.60005944 63,6.71983356 63,11.7569843 C63,14.1564296 63.8873915,16.4454131 65.4986948,18.2033882 C66.1570189,18.9216366 66.9181296,19.52447 67.7477648,20 L67.7477648,18.3430751 C67.3033349,18.0245691 66.8892515,17.6590053 66.5143249,17.2503467 C65.1408993,15.7524272 64.3846832,13.8017634 64.3846832,11.7569843 C64.3846832,7.49405587 67.6665144,4.00089162 71.8063695,3.70715276 L71.7128826,4.11085794 L72.0403315,4.10887656 L72.7392808,3.22518328 C73.1504275,3.27224094 73.6687659,3.32474737 74.2468185,3.35744006 L73.6418456,5.99564097 L74.1322848,5.9936596 L75.5913659,3.40548841 C76.1669712,3.4064791 76.6671996,3.39508619 77.0714938,3.34307509 C77.6407361,3.27768972 77.9995105,3.16524668 78,2.99782049 L78,2.9968298 C77.9990211,2.82989895 77.6407361,2.7184466 77.0714938,2.65157519"
            id="Fill-27"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default logo;
