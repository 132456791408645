import { connect } from 'react-redux';

import View from './view';
import { ReduxState } from '../../types/redux_types';

// connect App to redux for profile/account info
const mapStateToProps = (state: ReduxState) => ({
  isAuthenticated: state.global.isAuthenticated,
  selectedAccountId: state.global.selectedAccountId,
  isInternal: state.global.userDetails.isInternal,
});

export default connect(mapStateToProps)(View);
