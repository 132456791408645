import produce from 'immer';

import reducerRegistry from './reducerRegistry';
import isValid from '../lib/isValid';
import { Action, ActionType, GlobalState } from '../types/global_reducer';

const INITIAL_STATE: GlobalState = {
  isAuthenticated: false,
  userDetails: {},
  selectedAccountName: null,
  tailSearchResults: null,
  searchFieldValue: '',
  pageRefreshLoading: false,
  notes: [],
  pageMessage: {},
  debug: false,
  accountAircraft: [],
  accountAircraftIds: [],
};

const reducer = (state = INITIAL_STATE, action: Action): GlobalState =>
  produce<GlobalState>(state, (draft: GlobalState) => {
    switch (action.type) {
      case ActionType.AUTH_SUCCESS: {
        const { name, accounts, apps, isInternal, token, email, cob_unverified } = action.payload;
        const [allAccountIds, allAccounts] = accounts;

        draft.userDetails = {
          allAccountIds,
          name,
          apps,
          allAccounts,
          isInternal: isInternal || false,
          token,
          email,
          cob_unverified,
        };
        draft.isAuthenticated = true;
        draft.pageRefreshLoading = false;

        break;
      }

      case ActionType.AUTH_LOGOUT:
        draft.userDetails = {};
        draft.isAuthenticated = false;
        draft.selectedAccountId = null;
        draft.selectedAccountName = null;
        draft.tailSearchResults = null;
        draft.searchFieldValue = '';
        draft.notes = [];
        break;

      case ActionType.SET_SELECTED_ACCOUNT: {
        const { selectedApp, accountId } = action.payload;
        draft.selectedAccountId = accountId;

        const appKey = state.userDetails.isInternal ? '*' : selectedApp;
        draft.selectedAccountName = accountId
          ? state.userDetails.allAccounts?.[appKey || '']?.[action.payload.accountId || '']
              ?.accountName
          : null;

        break;
      }

      case ActionType.SET_MESSAGE: {
        draft.pageMessage = action.payload;

        break;
      }

      case ActionType.DELETE_MESSAGE: {
        const msgs = state.pageMessage?.message || [];
        draft.pageMessage = {
          type: state.pageMessage?.type,
          message: msgs.length > 1 ? msgs.splice(-1) : [],
          icon: state.pageMessage?.icon,
        };
        break;
      }

      case ActionType.DELETE_ALL_MESSAGES: {
        draft.pageMessage = {};

        break;
      }

      case ActionType.PAGE_REFRESH_LOADING: {
        draft.pageRefreshLoading = true;

        break;
      }

      case ActionType.SET_SEARCH_FIELD_VALUE: {
        draft.searchFieldValue = action.payload;

        break;
      }

      case ActionType.SET_AIRCRAFT: {
        const { payload } = action;
        // if we get a blank flightstatus, set it to GATE
        payload.forEach((aircraft, index) => {
          if (aircraft.flightStatus !== undefined && aircraft.flightStatus === null) {
            payload[index].flightStatus = 'GATE';
          }
        });
        const [accountAircraftIds, accountAircraft] = payload;

        draft.accountAircraft = accountAircraft;
        draft.accountAircraftIds = accountAircraftIds;

        break;
      }

      case ActionType.SET_NOTES: {
        draft.notes = action.payload;

        break;
      }

      case ActionType.SET_NOTE: {
        draft.notes.push(action.payload);

        break;
      }

      case ActionType.EDIT_NOTE: {
        draft.notes = state.notes.map(note => {
          const newNote = { ...note };

          if (note.timestamp === action.payload.newNote.timestamp) {
            if (isValid(action.payload.newNote.title) && isValid(action.payload.newNote.body)) {
              newNote.title = action.payload.newNote.title;
              newNote.body = action.payload.newNote.body;
              return newNote;
            }
            if (isValid(action.payload.newNote.title)) {
              newNote.title = action.payload.newNote.title;
              return newNote;
            }
            if (isValid(action.payload.newNote.body)) {
              newNote.body = action.payload.newNote.body;
              return newNote;
            }
          }
          return newNote;
        });
        break;
      }

      case ActionType.DELETE_NOTE: {
        draft.notes = state.notes.filter(note => note.timestamp !== action.payload.noteId);

        break;
      }

      default:
        return state;
    }
  });

reducerRegistry.register('global', reducer);

export default reducer;
