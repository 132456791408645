import 'whatwg-fetch';
import {
  getS3PresignedUrls,
  getReleaseNotesFromS3,
  putReleaseNoteInS3,
} from '../../../lib/restUtils';
import { Note } from '../../../types/global_reducer';

const apiUrl = '/api';
// const API_VERSION = 'v2'; // for bender
const API_VERSION = 'v1'; // for dev connectivity

export const apiGetPresignedUrl = (accountId: string): Promise<string> => {
  // http://dashcommons.dashapi.dev.gogoair.com/v1/accounts/<accountId>/releaseNotesGETUrl
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/releaseNotesGETUrl`;
  return getS3PresignedUrls(apiURL);
};

export const apiPutPresignedUrl = (accountId: string): Promise<string> => {
  // http://dashcommons.dashapi.dev.gogoair.com/v1/accounts/<accountId>/releaseNotesPUTUrl
  const apiURL = `${apiUrl}/${API_VERSION}/accounts/${accountId}/releaseNotesPUTUrl`;
  return getS3PresignedUrls(apiURL);
};

export const apiGetReleaseNotes = (url: string) => getReleaseNotesFromS3<Note[]>(url);

export const apiPutReleaseNote = (
  putUrl: string,
  getUrl: string,
  params: Note | Note[]
): Promise<number> => putReleaseNoteInS3(putUrl, getUrl, params);
