import moment from 'moment-timezone';

// eslint-disable-next-line import/prefer-default-export
export const utcToDayMonthYear = (date: moment.MomentInput, timezone?: string) => {
  let newDate;
  if (timezone) {
    newDate = moment(date).tz(timezone);
  } else {
    newDate = moment(date).local();
  }
  return `${newDate.format('MM/DD/YYYY')}`;
};
