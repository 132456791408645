import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { resetPassword } from '../../../../actions';
import { ReduxState } from '../../../../types/redux_types';
import { Action } from '../../../../types/global_reducer';

interface ForgotPasswordProps {
  username: string;
  handleUsernameChange: (e: ChangeEvent<HTMLInputElement>) => void;
  actions: {
    resetPassword: (username: string) => void;
  };
}

const ForgotPassword = ({ username, handleUsernameChange, actions }: ForgotPasswordProps) => {
  return (
    <div className="reset-password-form" data-test="reset-password-form">
      <input
        onChange={handleUsernameChange}
        type="text"
        value={username}
        placeholder="Email Address"
      />
      <p>Please enter your email address. We will send you an email to reset your password.</p>
      <button
        onClick={() => actions.resetPassword(username)}
        data-test="reset-password-button"
        type="submit"
      >
        Send Email
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    resetPassword: (user: string) => dispatch(resetPassword(user)),
  },
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
