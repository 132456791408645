import React from 'react';
import './index.css';

const Footer = (): JSX.Element => {
  return (
    <div className="main-footer">
      Copyright &copy; {new Date().getFullYear()} Gogo Inc. and its subsidiaries. All rights
      reserved.
    </div>
  );
};

export default Footer;
