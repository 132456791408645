import React from 'react';
import { Switch, Redirect, Link } from 'react-router-dom';
import queryString from 'querystring';
import { Location } from 'history';
import gogoLogo from '../../assets/GogoLogoSquareWhite.png';
import NavigationBar from '../../components/NavigationBar';
import './index.css';
import Footer from '../../components/Footer';
import ggDASHConfig from '../../ggDASHConfig';
import history from '../../history';
import { getUserData } from '../../lib/restUtils';
import loginVariables from '../Login/_variables';
import { JSAnyMap, JSMap } from '../../types/javascript_object';

const Home = (): JSX.Element => (
  <div className="home">
    <NavigationBar />
    <div className="container">
      <div className="presented-by-banner">
        Presented By:
        <img src={gogoLogo} alt="Gogo Logo" style={{ height: '30px', margin: '16px' }} />
      </div>
      <div id="auth0_container" className="auth0-container">
        <div className="log-in-wrapper">
          <img className="dashLogo" src="./logo.svg" alt="" />
          <Link className="log-in-button" to="/login">
            Log In
          </Link>
          <div className="sign-up-link">
            To sign up for Gogo DASH please contact{' '}
            <a href="https://www.gogoair.com/getdash/" target="_blank" rel="noopener noreferrer">
              Gogo
            </a>
            .
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

const setupLocalStorage = (authResult: JSAnyMap): void => {
  const expiresAt = new Date().getTime() + 1000 * authResult.expires_in;
  const permissionedAccounts = JSON.stringify(authResult['https://dash.gogoair.com/applications']);
  // add user info
  localStorage.setItem('expires_at', String(expiresAt));
  localStorage.setItem('id_token', authResult.id_token);
  localStorage.setItem('name', authResult.name);
  localStorage.setItem('permissionedAccounts', permissionedAccounts);
};

const checkIfRedirectedFromMainSite = (
  urlLocation: { search: string },
  actions: {
    loginAs: (
      name: string,
      accountList: JSMap<{ accountId: string; accountName: string }[]>,
      token: string,
      email: string,
      cob_unverified: boolean
    ) => void;
  }
): void => {
  if (urlLocation.search) {
    const queryParams = queryString.parse(urlLocation.search.substr(1));
    const { id_token: idToken, access_token: accessToken, expires_in: expiresIn } = queryParams;

    if (idToken && accessToken && expiresIn) {
      getUserData<JSAnyMap>(`https://${loginVariables.domain}/userinfo`, accessToken).then(
        response => {
          if (response && response.results) {
            const { results } = response;
            const {
              name,
              'https://dash.gogoair.com/applications': accountList,
              email,
              cob_unverified,
            } = results;
            setupLocalStorage({ expiresIn, idToken, ...results });
            actions.loginAs(name, accountList, idToken as string, email, cob_unverified);

            history.push('/');
          }
        }
      );
    }
  }
};

// if we're logged in just redirect to the dashboard
interface HomeProps {
  isAuthenticated: boolean;
  apps?: Set<string>;
  location: Location;
  actions: {
    loginAs: (
      name: string,
      accountList: JSMap<{ accountId: string; accountName: string }[]>,
      token: string,
      email: string,
      cob_unverified: boolean
    ) => void;
  };
}

export default ({ isAuthenticated, apps, location, actions }: HomeProps): JSX.Element => {
  let route = <Home />;

  if (isAuthenticated && apps) {
    let pathToApp = '/';
    const groundPortalPath = '/dashboard';
    const appsString = [...apps.values()].join(', ').toLowerCase();

    if (apps.size > 1 || apps.has(ggDASHConfig.apps.all)) {
      pathToApp += ggDASHConfig.apps.sso;
    } else if (appsString.includes(ggDASHConfig.apps.groundportal)) {
      pathToApp = groundPortalPath;
    } else {
      let app = (ggDASHConfig.apps as JSMap<string>)[[...apps]?.[0]?.toLowerCase()];
      if (app === 'servicerequest') {
        app = 'service-request';
      }
      pathToApp += app;
    }
    route = <Redirect to={pathToApp} />;
    return <Switch>{route}</Switch>;
  }

  checkIfRedirectedFromMainSite(location, actions);

  return <Switch>{route}</Switch>;
};
