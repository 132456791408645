import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getNotes, setNote, editNote, deleteNote } from './actions';
import Notes from './view';
import { ReduxState } from '../../types/redux_types';
import { Action, Note } from '../../types/global_reducer';

/* istanbul ignore next */
const mapStateToProps = (state: ReduxState) => ({
  notes: state.global.notes,
  isInternal: state.global.userDetails.isInternal,
  accountId: state.global.selectedAccountId,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, void, Action>) => ({
  actions: {
    getNotes: (accountId?: string | null) => dispatch(getNotes(accountId || '')),
    createNote: (accountId: string, params: Note) => dispatch(setNote(accountId, params)),
    editNote: (accountId: string, newNote: Note) => dispatch(editNote(accountId, newNote)),
    deleteNote: (accountId: string, noteId: string, note: Note) =>
      dispatch(deleteNote(accountId, noteId, note)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
